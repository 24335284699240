const config = {
  dev: {
    backendUrl: 'https://dev-api.hikoilu.net'
  },
  local: {
    backendUrl: 'http://localhost:9999'
  }
}

export default {
  ...config[process.env.REACT_APP_STAGE || 'local']
}
