import React, { useEffect, useState } from 'react'
import './App.css'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import connectWithStrava from './connectWithStrava.svg'
import api from './api.js'
import config from './config.js'

const data = [
  { name: 'Page A', uv: 4000, pv: 2400 },
  { name: 'Page B', uv: 3000, pv: 1398 },
  { name: 'Page C', uv: 2000, pv: 9800 },
  { name: 'Page D', uv: 2780, pv: 3908 },
  { name: 'Page E', uv: 1890, pv: 4800 },
  { name: 'Page F', uv: 2390, pv: 3800 },
  { name: 'Page G', uv: 3490, pv: 4300 }
]

const fetchAndSet = (url, setter) =>
  api
    .get(url)
    .catch(({ response: { status } }) => {
      if (status === 401) {
        return undefined
      } else {
        throw new Error(`Don't know what to do with ${status}`)
      }
    })
    .then(setter)

const Graph = () => {
  const startGathering = e => {
    e.preventDefault()
    api.post('start-gathering')
  }

  return (
    <div>
      <ResponsiveContainer>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" stackId="a" fill="#8884d8" />
          <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
      <form method="post" onSubmit={startGathering}>
        <button type="submit">Update</button>
      </form>
    </div>
  )
}

const Login = () => {
  return (
    <a href={`${config.backendUrl}/login/strava?redirect=${window.location.href}`}>
      <img src={connectWithStrava} className="" alt="Connect with strava" />
    </a>
  )
}

const App = () => {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    fetchAndSet('user', setUser).then(() => {
      setLoading(false)
    })
  }, [])

  const Main = () => {
    return user ? <Graph /> : <Login />
  }

  return loading ? null : (
    <div style={{ width: '80%', height: 600 }}>
      <Main />
    </div>
  )
}

export default App
